import React from 'react';
import atom from "assets/atom.svg";
import Box from "@mui/material/Box";
import Session from 'utils/Session';
import OnboadingHeader from 'components/OnboadingHeader';
import useNavigateWithClient from "hooks/useNavigateWithClient";
import StartAssessmentForAPI from './StartAssessmentForAPI';

import { useLoading } from "contexts/LoadingProvider";
import { useSnackbar } from "contexts/SnackbarProvider";
import { useNavigate, useSearchParams } from 'react-router-dom';
import { signOut, verifyToken, restoreSession } from 'services/auth';

function Auth() {
  const navigate = useNavigate();
  const loading = useLoading();
  const snackbar = useSnackbar();
  const [searchParams] = useSearchParams();
  const navigateWithPrefix = useNavigateWithClient();
  const next = (url) => navigateWithPrefix(url);

  const [verified, setVerified] = React.useState(false);

  React.useEffect(() => {
    (async () => {
      try {
        loading.show();

        setVerified(false);
        snackbar.info("Your request is being authenticated, Please wait...");

        if (Session.isLoggedIn()) {
          navigateWithPrefix("/home");
          return;
        }

        try {
          console.log("DEBUG::Restore session");

          await restoreSession();
          navigateWithPrefix("/home");
          return;
        } catch (error) {
          console.log("DEBUG::Failed to Restore session");
        }

        const token = searchParams.get("at");

        if (!token) {
          signOut();
          navigate("/error");
          return;
        }

        const result = await verifyToken(token);
        // Successfully verified
        if (!result) {
          navigate("/error");
          setVerified(false);
          return;
        }

        setVerified(true);
        snackbar.success("Authenticated Successfully");
        next('/home');
      } catch (error) {

        console.log("Error: ", error);
        navigate("/error");
      } finally {

        loading.hide();
        snackbar.hideSnackbar();
      }
    })();
  }, []);

  return (
    <>
      <OnboadingHeader />
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        height={"calc(100vh - 8vh)"}
        position="relative"
      >
        {!verified && (
          <img
            alt="verifying"
            src={atom}
            style={{
              width: "-webkit-fill-available",
              height: "-webkit-fill-available"
            }}
          />)}
      </Box>
    </ >
  );
}
export function AuthForAssessmentLink() {
  const navigate = useNavigate();
  const loading = useLoading();
  const snackbar = useSnackbar();
  const [searchParams] = useSearchParams();
  const [verified, setVerified] = React.useState(false);
  const [templateId, setTemplateId] = React.useState(null);

  React.useEffect(() => {
    (async () => {
      try {
        loading.show();

        setVerified(false);
        snackbar.info("Your request is being authenticated, Please wait...");

        const token = searchParams.get("at");

        if (!token) {
          signOut();
          navigate("/error");
          return;
        }

        const result = await verifyToken(token);
        // Successfully verified

        if (!result) {
          navigate("/error");
          setVerified(false);
          return;
        }

        setVerified(true);
        setTemplateId(result.data.templateId);

        snackbar.success("Authenticated Successfully");
      } catch (error) {

        console.log("Error: ", error);
        navigate("/error");
      } finally {

        loading.hide();
        snackbar.hideSnackbar();
      }
    })();
  }, []);

  return (
    <>
      {
        !templateId ?
          <Box>
            <OnboadingHeader />
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              height={"calc(100vh - 8vh)"}
              position="relative"
            >
              {!verified && (
                <img
                  alt="verifying"
                  src={atom}
                  style={{
                    width: "-webkit-fill-available",
                    height: "-webkit-fill-available"
                  }}
                />)}
            </Box>
          </Box> :
          <StartAssessmentForAPI templateId={templateId} />
      }
    </ >
  );
}

export default Auth;