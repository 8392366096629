import React from 'react'
import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import makeStyles from "@mui/styles/makeStyles";

import Chip from 'components/Chip';
import CustomButton from 'components/CustomButton';

const useStyles = makeStyles(theme => ({
  createInterviewContainer: {
    padding: theme.spacing(7, 10, 10, 10),
    marginTop: theme.spacing(4),
    border: '2px solid',
    borderColor: theme.palette.tertiary.main,
    borderRadius: theme.spacing(1),
    backgroundColor: theme.palette.shades['clr-white-900'],
    boxShadow: '0px 0px 4px 0px #00000026',
    display: 'flex',
    justifyContent: 'space-between',
    overflow: 'hidden',
    maxHeight: '270px'
  },
  contentBox: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(13),
    width: '638px'
  },
  headBox: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
  heading: {
    display: 'flex',
    gap: theme.spacing(2),
  },
  imageBox: {
    display: 'flex',
    alignItems: 'center',
    margin: `-${theme.spacing(7)} -${theme.spacing(0)} -${theme.spacing(10)} 0`,
    maxHeight: '100%',
    overflow: 'hidden',
  },
  image: {
    height: '270px',
    objectFit: 'cover',
  }
}));

const CreateInterviewBanner = () => {
  const classes = useStyles();
  const theme = useTheme();
  const navigate = useNavigate();

  return (
    <Box className={classes.createInterviewContainer}>
      <Box className={classes.contentBox}>
        <Box className={classes.headBox}>
          <Box className={classes.heading}>
            <Typography variant='h3-medium' color='neutral.clr-800'>
              Personalized Interview Prep
            </Typography>
            <Chip
              content='Newly Launched'
              endIcon={<img
                width={20}
                height={20}
                src='https://assets.languify.in/images/Stars.svg'
                alt='new'
              />}
              color={theme.palette.tertiary['clr-700']}
              bgColor={theme.palette.tertiary['clr-100']}
              typographyVariant={'h6-medium'}
            />
          </Box>
          <Typography variant='h6-medium' color='neutral.clr-700'>
            Create and practice personalized interview questions with AI, whether it's for a job interview or a UPSC interview.
          </Typography>
        </Box>
        <CustomButton
          variant='contained'
          size='large'
          sx={{ width: '170px' }}
          onClick={() => navigate('create')}
        >
          Practice Now
        </CustomButton>
      </Box>
      <Box className={classes.imageBox}>
        <img
          src='https://assets.languify.in/images/createMock.png'
          alt='interview'
          width={340}
          height={240}
        />
      </Box>
    </Box>
  );
}

export default CreateInterviewBanner;
