import React , {useEffect , useState} from 'react'
import makeStyles from "@mui/styles/makeStyles";
import { Box, Divider, Typography } from '@mui/material';

import { useServices } from 'contexts';
import { extractPdf} from 'services';
import { TextArea } from 'components/CustomFields';
import { errorStateTexts } from './constants';
import { nextStepAudio } from 'utils';
import CustomButton from 'components/CustomButton';
import FileUpload from 'components/FileUpload';
import LinearProgressBar from 'components/LinearProgressBar';
import FileUploadErrorDialog from 'dialogs/FileUploadErrorDialog';

const useStyles = makeStyles((theme) => ({
  container : {
    display:'flex',
    flexDirection:'column',
    gap:theme.spacing(5),
    marginTop : theme.spacing(13),
    animation: '$slideInFromRight 0.6s ease-out',
  },
  containerBack : {
    display:'flex',
    flexDirection:'column',
    gap:theme.spacing(5),
    marginTop : theme.spacing(13),
    animation: '$slideInFromLeft 0.6s ease-out',
  },
  '@keyframes slideInFromRight': {
    '0%': {
      transform: 'translateX(15px)',
      opacity: 0
    },
    '100%': {
      transform: 'translateX(0)',
      opacity: 1
    }
  },
  '@keyframes slideInFromLeft': {
    '0%': {
      transform: 'translateX(-15px)',
      opacity: 0
    },
    '100%': {
      transform: 'translateX(0)',
      opacity: 1
    }
  },
  uploadCaseBox:{
    display:'flex',
    flexDirection:'column',
    justifyContent:'center',
    alignItems:'center',
    gap:theme.spacing(3),
    width:'100%',
    height:'132px',
    borderRadius:theme.spacing(1),
    border:`1px solid ${theme.palette.neutral['clr-500']}`,
    padding : theme.spacing(2)
  },
  errorImage:{
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
  },
}))

const CaseStudy = ({
  stepNumber , setStepNumber , fileName , setFileName,setFinalReport,
  isNextStep, setIsNextStep
}) => {
  const classes = useStyles();
  const service = useServices();

  const [error , setError] = useState(false)
  const [report , setReport] = useState('')
  const [isFileUploaded , setIsFileUploaded] = useState(false)
 
  const [fileNameReceived , setFileNameReceived] = useState(false)
  const [scrapedData , setScrapedData] = useState('')
  const [progressBar , setProgressBar] = useState(false)
  const [progressionComplete , setProgressionComplete] = useState(false)
  const [errorText , setErrorText] = useState('')
  
  useEffect(()=> {
    if(error){
      setFileNameReceived(false)
    }else if(scrapedData){
      setFinalReport(scrapedData)
    }else{
      setFinalReport(report)
    }
  },[scrapedData , report , error])

  useEffect(()=> {
    if(fileNameReceived){
      setError(false)
      setProgressBar(true)
    }
  },[fileNameReceived])

  const pdfToTextScrape = async (file) => {
    try {
      const result = await service.callService(extractPdf, file);
      return result;
      
    } catch (error) {
      console.error("Error extracting text from PDF:", error);
      throw error;
    }
  };

  const handleReportChange = (e) => {
    setReport(e.target.value)
  }

  const handlePdfScrape = async(selectedFile) => {

     if (selectedFile.type !== "application/pdf") {
      setError(true);
      setErrorText(errorStateTexts.not_pdf_file); 
      return false;
    }

    const scrapedData = await extractResumeData(selectedFile);

    if(scrapedData){
      setScrapedData(scrapedData)
      let fileNameWithoutExtension = selectedFile.name.replace(/\.pdf$/i, '');
      setFileName(fileNameWithoutExtension)
      setFileNameReceived(true)
      setIsFileUploaded(true)
     
      return true;
    }
  }

  const extractResumeData = async (file) => {
    try {
      const extractedText = await pdfToTextScrape(file);

      if (!extractedText || extractedText.trim().length === 0) {
        setError(true)
        setErrorText(errorStateTexts.scanned_document)
        return null;
      }else{
        return extractedText;
      }
    } catch (error) {
      setError(true)
      setErrorText(errorStateTexts.pdf_upload_fail)
      console.error("Failed to extract text from PDF:", error);
      return null;
    }
  };

  return (
        <Box>
          <Box className={isNextStep ? classes.container : classes.containerBack}>
            <Typography variant='h5-medium' color='neutral.clr-600'>
              Add report for questions generation
            </Typography>
            <TextArea
              label='Report content'
              rows={7}
              placeholder='Paste the content here...'
              sx={{
                width: '100%',
              }}
              value={report}
              onChange={handleReportChange}
              disabled={fileNameReceived}
            />
            <Box display='flex' justifyContent='space-between' position='relative'>
              <Divider sx={{width : '145px' , borderColor : '#8692A3'}} />
              <Typography 
                variant='body01-bold' 
                color='neutral.clr-400' 
                sx={{position : 'absolute' , top : '-10px', left : '46%'}}
              >
                  OR
              </Typography>
              <Divider sx={{width : '145px' , borderColor : '#8692A3'}} />
            </Box>
            <Box className={classes.uploadCaseBox}>
                {(progressBar && fileNameReceived ) 
                ? 
                  ( <Box className={classes.progressBarContainer}>
                      <LinearProgressBar 
                        fileNameReceived={fileNameReceived}
                        setFileNameReceived={setFileNameReceived}
                        isFileUploaded={isFileUploaded}
                        fileName={fileName}
                        error={error}
                        setProgressBar={setProgressBar}
                        isCaseStudy={true}
                        setProgressionComplete={setProgressionComplete}
                      />
                        {progressionComplete && (
                        <Box 
                            sx={{width:'100%' , display:'flex' , justifyContent:'flex-end' }}
                          >
                            <Typography 
                              variant='body01-link' 
                              color='primary.clr-300'
                              sx={{cursor:'pointer'}}
                              onClick={()=>{  
                                setProgressBar(false) 
                                setIsFileUploaded(false)
                                setFileNameReceived(false)
                                setScrapedData('')
                                setProgressionComplete(false)
                              }}
                            >
                              Change case study?
                            </Typography>
                          </Box>
                        )}
                      </Box>
                  ) 
                : 
                  (
                    <>
                      <FileUpload 
                        name="thumbnail"
                        label="Upload PDF file"
                        onFileSelect={handlePdfScrape}
                        uploadOnAws={false}
                        setErrors={setError}
                        accept={['.pdf']} 
                        variant='outlined'
                        sx={{width : '196px'}}
                        disabled={report}
                      />
                  
                  </>
                )}
                
            </Box>
          </Box>
          <CustomButton
            sx={{
              position : 'absolute',
              bottom : '30px',
              width : '336px'
            }}
            size='large'
            disabled={!report && !fileNameReceived}
            onClick={() => {
              setIsNextStep(true)
              nextStepAudio();
              setStepNumber(stepNumber + 1)
            }}
          >
            Next
          </CustomButton>
          <FileUploadErrorDialog
            open={error}
            fileUploadProp={  
              <FileUpload 
                name="thumbnail"
                label="Upload"
                onFileSelect={handlePdfScrape}
                uploadOnAws={false}
                setErrors={setError}
                accept={['.pdf']} 
                buttonSize='medium'
                sx={{width:'290px'}}
              />}
            title={<Box color='danger.clr-700'>File upload failed!</Box>}
            message={
              <Box className={classes.errorImage}>
                <Typography variant='h6-regular' color='nuetral.clr-900'>
                  {errorText}
                </Typography>
              </Box>
            }
            buttonSize='medium'
            setProgressBar={setProgressBar}
            fileNameReceived={fileNameReceived}
            isCaseStudy={true}
            onSecondaryAction={() => {
              setError(null);
            }}
          />
       
        </Box>
  )
}

export default CaseStudy