import React, { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { debounce } from 'lodash';
import makeStyles from "@mui/styles/makeStyles";
import dayjs from 'dayjs';

import { useServices } from 'contexts';
import { useNavigateWithClient } from 'hooks';
import { getAverageScore, getTwoDigitNo, round } from 'utils';
import { getPersonalizedInterviewAttempts } from 'services';
import { WhiteBox, WhiteBoxHeader } from 'components/AppShell';
import { SearchTextField } from 'components/CustomFields';
import DataGrid from 'components/DataGrid';
import NoAssessmentsFound from 'components/NoAssessmentsFound';
import Pagination from 'components/Pagination';
import StartAssessmentDialog from 'dialogs/StartAssessmentDialog';
import { PersonalizedTemplateTypeName } from 'components/CreateInterview/constants';
import { ProctorCell } from 'components/OverallPerformance/ProctorCell';
import ImageProctorDialog from 'dialogs/ImageProctorDialog';
import { BootstrapTooltip } from 'components/InfoIconWithTooltip';
import { Interviewers } from 'components/Assessment/constants';
import { InterviewRoundsmap } from './constant';


const useStyles = makeStyles((theme) => ({
  tableHeader: {
    backgroundColor: theme.palette.shades['clr-grey-100'],
    ...theme.typography['body01-bold'],
    textAlign: "center",
    lineHeight: 'none',
    color: theme.palette.shades['clr-black-900'],
  },
  row: {
    color: theme.palette.neutral['clr-900'],
    ...theme.typography['body01-medium'],
    cursor: "pointer",
    '&:hover': {
      backgroundColor: `${theme.palette.neutral['clr-50']} !important`,
    },
  },
  LockRoot: {
    borderRadius: '50%',
    height: '34px', width: '34px',
    display: 'flex', alignItems: 'center', justifyContent: 'center'
  },
  textWrap: {
    maxWidth: '170px',  
    whiteSpace: 'nowrap',
    overflow: 'hidden',  
    textOverflow: 'ellipsis',
    textTransform: 'capitalize'
  },
}));

const AttemptTable = () => {
  const classes = useStyles();
  const service = useServices();
  const navigateWithClient = useNavigateWithClient();

  const [attempts, setAttempts] = useState([]);
  const [filteredAttempts, setFilteredAttempts] = useState([]);
  const [assessment, setAssessment] = useState(false);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [searchQuery, setSearchQuery] = useState('');
  const [proctoringRecords, setProctoringRecords] = useState([]);
  const [showImageModal, setShowImageModal] = useState(false);

  const handleViewCapturedImage = (pr) => {
    setProctoringRecords(pr);
    setShowImageModal(true);
  }

  const columns = [
    {
      field: "name",
      headerName: "Interview Name",
      valueFormatter: (row) => row?.value?.name,
      headerClassName: classes.tableHeader,
      flex: 1,
      minWidth: 190,
      align: "left",
      headerAlign: "left",
      disableColumnMenu: true,
      sortable: false,
      renderCell: ({ row }) => {
        const { interviewTemplate } = row;

        return (
          <BootstrapTooltip 
            title={interviewTemplate.name} 
            placement="top" arrow 
            disableHoverListener={interviewTemplate.name.length < 20}
          >
            <Box display='flex' gap={3} alignItems='center'>
              <Typography className={classes.textWrap} variant='body01-medium'>
                { interviewTemplate.name }
              </Typography>
            </Box>
          </BootstrapTooltip>
        )
      }
    },
    {
      field: "type",
      headerName: "Type",
      flex: 1,
      sortable: false,
      minWidth: 130,
      headerClassName: classes.tableHeader,
      disableColumnMenu: true,
      renderCell: ({row}) => {
        const {interviewTemplate} = row;
        return PersonalizedTemplateTypeName[interviewTemplate.metadata.personalizedTemplateType]
      }
    },
    {
      field: "interviewRound",
      headerName: "Round",
      flex: 1,
      minWidth: 140,
      sortable: false,
      headerClassName: classes.tableHeader,
      disableColumnMenu: true,
      renderCell: ({ row }) => {
        const { interviewTemplate } = row;
        return (
          <BootstrapTooltip 
            title={interviewTemplate?.metadata?.interviewRound} 
            placement="top" arrow 
            disableHoverListener={interviewTemplate?.metadata?.interviewRound?.length < 15}
          >
            <Box display='flex' gap={3} alignItems='center'>
              <Typography className={classes.textWrap} variant='body01-medium'>
                { InterviewRoundsmap[interviewTemplate?.metadata?.interviewRound]?.name || "--" }
              </Typography>
            </Box>
          </BootstrapTooltip>
        )
      }
    },
    {
      field: "createdAt",
      headerName: "Attempted On",
      align: "center",
      headerAlign: "center",
      flex: 1,
      minWidth: 140,
      sortable: false,
      valueFormatter: (params) => dayjs(params.value).format('DD/MM/YYYY'),
      headerClassName: classes.tableHeader,
      disableColumnMenu: true,
    },
    {
      field: "time",
      headerName: "Completed in",
      align: "center",
      headerAlign: "center",
      flex: 1,
      minWidth: 140,
      sortable: false,
      headerClassName: classes.tableHeader,
      disableColumnMenu: true,
      renderCell: ({ row }) => {
        const diffInMs = dayjs(row?.finishedAt).diff(dayjs(row?.createdAt));
        const minutes = Math.floor(diffInMs / 60000);
        const seconds = Math.floor((diffInMs % 60000) / 1000);

        return `${getTwoDigitNo(minutes)}:${getTwoDigitNo(seconds)} min`;
      }
    },
    {
      field: "proctor",
      headerName: "Proctor",
      align: "center",
      headerAlign: "center",
      flex: 1,
      minWidth: 120,
      sortable: false,
      headerClassName: classes.tableHeader,
      disableColumnMenu: true,
      renderCell: ({ row }) => (
        <ProctorCell
          proctoringRecords={row.proctoringRecords}
          onViewCapturedImage={handleViewCapturedImage}
        />
      ),
    },
    {
      field: "overallScore",
      headerName: "Overall score",
      align: "center",
      headerAlign: "center",
      flex: 1,
      minWidth: 120,
      valueGetter: ({ row }) => {
        const comm = row.analysis.communication.ratings.OVERALL || 0;
        const content = row.analysis.content.ratings.OVERALL || 0;

        return round(getAverageScore(comm, content));
      },
      headerClassName: classes.tableHeader,
      disableColumnMenu: true,
      sortable: false,
    },
    {
      field: "communication",
      headerName: "Speech score",
      align: "center",
      headerAlign: "center",
      flex: 1,
      minWidth: 120,
      valueGetter: ({ row }) => round(row.analysis.communication.ratings.OVERALL) || 0,
      headerClassName: classes.tableHeader,
      disableColumnMenu: true,
      sortable: false,
    },
    {
      field: "content",
      headerName: "Content score",
      align: "center",
      headerAlign: "center",
      flex: 1,
      minWidth: 130,
      valueGetter: ({ row }) => round(row.analysis.content.ratings.OVERALL) || 0,
      headerClassName: classes.tableHeader,
      disableColumnMenu: true,
      sortable: false,
    },
    {
      field: "interviewer",
      headerName: "Interviewer",
      align: "center",
      headerAlign: "center",
      flex: 1,
      minWidth: 120,
      headerClassName: classes.tableHeader,
      disableColumnMenu: true,
      sortable: false,
      renderCell: ({ row }) => {
        return Interviewers[row?.interviewTemplate?.metadata?.interviewer]?.name || Interviewers.Niamh.name
      }
    },
  ];

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);

        const filter = {
          order: 'desc',
          orderBy: 'createdAt',
          includeTotal: true,
          include: ['interviewTemplate'],
        }
  
        const {interviewAttempts} = await service.callService(
          getPersonalizedInterviewAttempts, filter
        );
  
        setAttempts(interviewAttempts);
        setFilteredAttempts(interviewAttempts);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    })();
  }, [page, searchQuery]);

  useEffect(() => {
      if (searchQuery.length > 0) {
        const filtered = attempts.filter((attempt) =>
          attempt.interviewTemplate.name.toLowerCase().includes(searchQuery.toLowerCase())
        );
        setFilteredAttempts(filtered);
      } else {
        setFilteredAttempts(attempts);
      }
    }, [searchQuery, attempts]);

  const handleChange = (e) => {
    const value = e.target.value.trim();
    setSearchQuery(value);
  };

  const handleInput = debounce(handleChange, 500);

  return (
    <WhiteBox sx={{ marginTop: '16px' }}>
      <WhiteBoxHeader
        heading="My attempts"
        subheading="Attempt wise performance of personalized interviews"
      >
        <SearchTextField
          placeholder="Find your interview"
          onChange={handleInput}
          disabled={!attempts?.length && !searchQuery?.length > 0}
        />
      </WhiteBoxHeader>

      {filteredAttempts?.length ? (
        <DataGrid
          hideFooter
          loading={loading}
          paginationMode={'client'}
          disableRowSelectionState={true}
          filterMode={'client'}
          sortingMode={'client'}
          height="324px"
          rows={filteredAttempts}
          columns={columns}
          rowCount={filteredAttempts.length}
          pageSize={5}
          page={page}
          onPageChange={setPage}
          components={{ Pagination: null }}
          getRowClassName={(params) => classes.row}
          density="standard"
          noRowsMessage={"Seems like, you haven't taken any assessment yet!"}
          onRowClick={(params) => {
            if(params.row.totalAttemptsCount !== 0){
              navigateWithClient(`/performance/${params.id}`)
            }}}
        />
      ) : (
        <NoAssessmentsFound
          icon={'https://assets.languify.in/images/NoPersonalizedAssessment.svg'}
          label={
                filteredAttempts.length === 0 && searchQuery 
                    ? <>No matches found.<br/> Did you spell everything correctly?</> 
                    : <>You have not taken any personalized interview so far!</>
                }
        />
      )}
      {!filteredAttempts ? null :
        <Box p={2} display="flex" justifyContent="center" mt={2}>
          <Pagination
            count={Math.ceil(filteredAttempts.length/5)}
            page={page}
            setPage={setPage}
          />
        </Box>
      }

      <StartAssessmentDialog
        onClose={() => setAssessment(undefined)}
        open={!!assessment}
        template={assessment}
        userMock={true}
      />
      <ImageProctorDialog
        open={showImageModal}
        proctoringRecords={proctoringRecords}
        onClose={() => setShowImageModal(false)}
      />
    </WhiteBox>
  );
}

export default AttemptTable;
