export const DocContent = [
  {
    title: 'Resume only',
    description: '(If you want to practice for a job role, Irrelevant of any company)',
    img: 'https://assets.languify.in/images/resume.png',
  },
  {
    title: 'Job description & Resume',
    description: '(You just select a company & upload your resume)',
    img: 'https://assets.languify.in/images/jdresume.png',
  },
  {
    title: 'Job description only',
    description: '(If you don’t have your resume, You can also practice without it)',
    img: 'https://assets.languify.in/images/jd.png',
  },
  {
    title: 'Reports',
    description: '(Case study, Product teardown, Project reports & more)',
    img: 'https://assets.languify.in/images/report.png'
  }
];

export const InterviewRounds = [
  "Telephonic Round",
  "Team Lead Round",
  "Managerial Round",
  "HR Round"
]

export const InterviewRoundsmap = {
  telephonic: {
    _id: 'telephonic',
    name: 'Telephonic'
  },
  teamLead: {
    _id: 'teamLead',
    name: 'Team Lead'
  },
  managerial: {
    _id: 'managerial',
    name: 'Managerial'
  },
  hr: {
    _id: 'hr',
    name: 'HR'
  },
}