import React, { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { debounce } from 'lodash';
import makeStyles from "@mui/styles/makeStyles";
import dayjs from 'dayjs';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';

import { useSnackbar } from 'notistack';
import { useServices } from 'contexts';
import { useNavigateWithClient } from 'hooks';
import { getAverageScore, round } from 'utils';
import { getPersonalizedInterview } from 'services';
import { WhiteBox, WhiteBoxHeader } from 'components/AppShell';
import { SearchTextField } from 'components/CustomFields';
import { BootstrapTooltip } from 'components/InfoIconWithTooltip';
import DataGrid from 'components/DataGrid';
import NoAssessmentsFound from 'components/NoAssessmentsFound';
import Pagination from 'components/Pagination';
import CustomButton, { IconButton } from 'components/CustomButton';
import StartAssessmentDialog from 'dialogs/StartAssessmentDialog';
import { PersonalizedTemplateTypeName } from 'components/CreateInterview/constants';
import { Interviewers } from 'components/Assessment/constants';
import { InterviewRoundsmap } from './constant';


const useStyles = makeStyles((theme) => ({
  tableHeader: {
    backgroundColor: theme.palette.shades['clr-grey-100'],
    ...theme.typography['body01-bold'],
    textAlign: "center",
    lineHeight: 'none',
    color: theme.palette.shades['clr-black-900'],
  },
  row: {
    color: theme.palette.neutral['clr-900'],
    ...theme.typography['body01-medium'],
    cursor: "pointer",
    '&:hover': {
      backgroundColor: `${theme.palette.neutral['clr-50']} !important`,
    },
  },
  LockRoot: {
    borderRadius: '50%',
    height: '34px', width: '34px',
    display: 'flex', alignItems: 'center', justifyContent: 'center'
  },
  textWrap: {
    maxWidth: '170px',  
    whiteSpace: 'nowrap',
    overflow: 'hidden',  
    textOverflow: 'ellipsis',
    textTransform: 'capitalize'
  },
}));

const PersonalizedInterviewTable = () => {
  const classes = useStyles();
  const snackbar = useSnackbar();
  const service = useServices();
  const navigateWithClient = useNavigateWithClient();

  const [templates, setTemplates] = useState([]);
  const [assessment, setAssessment] = useState();
  const [filteredTemplates, setFilteredTemplates] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [searchQuery, setSearchQuery] = useState('');

  const columns = [
    {
      field: "name",
      headerName: "Personalized Interview",
      valueFormatter: (row) => row?.value?.name,
      headerClassName: classes.tableHeader,
      flex: 1,
      minWidth: 200,
      align: "left",
      headerAlign: "left",
      disableColumnMenu: true,
      sortable: false,
      renderCell: ({ row }) => {
        const { name } = row;

        return (
          <BootstrapTooltip 
            title={name} 
            placement="top" arrow 
            disableHoverListener={name.length < 20}
          >
            <Box display='flex' gap={3} alignItems='center'>
              <Typography className={classes.textWrap} variant='body01-medium'>
                { name }
              </Typography>
            </Box>
          </BootstrapTooltip>
        )
      }
    },
    {
      field: "type",
      headerName: "Type",
      flex: 1,
      minWidth: 130,
      sortable: false,
      headerClassName: classes.tableHeader,
      disableColumnMenu: true,
      renderCell: ({row}) => {
        const {metadata} = row;
        return PersonalizedTemplateTypeName[metadata.personalizedTemplateType]
      }
    },
    {
      field: "interviewRound",
      headerName: "Round",
      flex: 1,
      minWidth: 150,
      sortable: false,
      headerClassName: classes.tableHeader,
      disableColumnMenu: true,
      renderCell: ({ row }) => {
        return (
          <BootstrapTooltip 
            title={row?.metadata?.interviewRound} 
            placement="top" arrow 
            disableHoverListener={row?.metadata?.interviewRound?.length < 15}
          >
            <Box display='flex' gap={3} alignItems='center'>
              <Typography className={classes.textWrap} variant='body01-medium'>
                { InterviewRoundsmap[row?.metadata?.interviewRound]?.name || "--" }
              </Typography>
            </Box>
          </BootstrapTooltip>
        )
      }
    },
    {
      field: "overallScore",
      headerName: "Overall score",
      align: "center",
      headerAlign: "center",
      flex: 1,
      minWidth: 130,
      valueGetter: ({ row }) => {
        const comm = round(row.communicationAverage) || 0;
        const content = round(row.contentAverage) || 0;

        return round(getAverageScore(comm, content));
      },
      headerClassName: classes.tableHeader,
      disableColumnMenu: true,
      sortable: false,
    },
    {
      field: "communication",
      headerName: "Speech score",
      align: "center",
      headerAlign: "center",
      flex: 1,
      minWidth: 130,
      valueGetter: ({ row }) =>
        round(row.communicationAverage) || 0,
      headerClassName: classes.tableHeader,
      disableColumnMenu: true,
      sortable: false,
    },
    {
      field: "content",
      headerName: "Content score",
      align: "center",
      headerAlign: "center",
      flex: 1,
      minWidth: 130,
      valueGetter: ({ row }) =>
        round(row.contentAverage) || 0,
      headerClassName: classes.tableHeader,
      disableColumnMenu: true,
      sortable: false,
    },
    {
      field: "actions",
      headerName: "Actions",
      align: "center",
      minWidth: 150,
      headerAlign: "center",
      flex: 1,
      headerClassName: classes.tableHeader,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => {
        const { totalAttemptsCount, expiresOn } = row;
        const attempted = totalAttemptsCount > 0;

        return (
          attempted ?
            <CustomButton
              sx={{ width: '100%' }}
              variant='outlined'
              onClick={(e) => {
                e.stopPropagation();
                setAssessment(row)
              }}
              disabled={ dayjs().isAfter(expiresOn) }
            >
              Retake
            </CustomButton>
          :
            <CustomButton
              sx={{ width: '100%' }}
              onClick={(e) => {
                e.stopPropagation();
                setAssessment(row)
              }}
              disabled={ dayjs().isAfter(expiresOn) }
            >
              Attempt now
            </CustomButton>
        )
      },
    },
    {
      field: "feedback",
      headerName: "Feedback",
      align: "center",
      headerAlign: "center",
      flex: 1,
      minWidth: 120,
      headerClassName: classes.tableHeader,
      disableColumnMenu: true,
      sortable: false,
      renderCell: ({ row }) => {
        const { totalAttemptsCount } = row;
        const attempted = totalAttemptsCount > 0;

        return attempted ?(
          <BootstrapTooltip 
            title={<>View feedback</>} 
            placement='top'
          >
          <Box>
            <IconButton>
              <RemoveRedEyeOutlinedIcon/>
            </IconButton>
          </Box>
          </BootstrapTooltip>
        )
        :
        <IconButton disabled={true}>
          <RemoveRedEyeOutlinedIcon/>
        </IconButton>
      },
    },
    {
      field: "totalAttemptsCount",
      headerName: "Your attempts",
      align: "center",
      headerAlign: "center",
      flex: 1,
      minWidth: 130,
      headerClassName: classes.tableHeader,
      disableColumnMenu: true,
      sortable: false,
    },
    {
      field: "createdAt",
      headerName: "Created On",
      align: "center",
      headerAlign: "center",
      flex: 1,
      minWidth: 150,
      sortable: false,
      valueFormatter: (params) => dayjs(params.value).format('DD/MM/YYYY'),
      headerClassName: classes.tableHeader,
      disableColumnMenu: true,
    },
    {
      field: "interviewer",
      headerName: "Interviewer",
      align: "center",
      headerAlign: "center",
      flex: 1,
      minWidth: 150,
      headerClassName: classes.tableHeader,
      disableColumnMenu: true,
      sortable: false,
      renderCell: ({ row }) => {
        return Interviewers[row?.metadata?.interviewer]?.name || Interviewers.Niamh.name
      }
    },
  ];

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
  
        let allFilters = {
          type: "personalized",
        };
  
        const { interviewTemplates } = await service.callService(
          getPersonalizedInterview,
          allFilters
        );
        
        const sortedTemplates = interviewTemplates.sort((a, b) => {
          return new Date(b.createdAt) - new Date(a.createdAt);
        });
  
        setTemplates(sortedTemplates);
        setFilteredTemplates(sortedTemplates);
      } catch (error) {
        console.log(error);
        snackbar.error('Unable to load attempts');
      } finally {
        setLoading(false);
      }
    })();
  }, []);  

  useEffect(() => {
    if (searchQuery.length > 0) {
      const filtered = templates.filter((template) =>
        template.name.toLowerCase().includes(searchQuery.toLowerCase())
      );
      setFilteredTemplates(filtered);
    } else {
      setFilteredTemplates(templates);
    }
  }, [searchQuery, templates]);

  const handleChange = (e) => {
    const value = e.target.value.trim();
    setSearchQuery(value);
  };

  const handleInput = debounce(handleChange, 500);

  return (
    <WhiteBox sx={{ marginTop: '16px' }}>
      <WhiteBoxHeader
        heading="My performance"
        subheading="View feedback, overview and list of all interviews"
      >
        <SearchTextField
          placeholder="Search here"
          onChange={handleInput}
          disabled={!templates?.length && !searchQuery?.length > 0}
        />
      </WhiteBoxHeader>

      {filteredTemplates?.length ? (
        <DataGrid
          hideFooter
          loading={loading}
          paginationMode={'client'}
          filterMode={'client'}
          sortingMode={'client'}
          height="324px"
          rows={filteredTemplates}
          disableRowSelectionState={true}
          columns={columns}
          rowCount={filteredTemplates.length}
          pageSize={5}
          page={page}
          onPageChange={setPage}
          components={{ Pagination: null }}
          getRowClassName={(params) => classes.row}
          density="standard"
          noRowsMessage={"Seems like, you haven't taken any assessment yet!"}
          onRowClick={(params) => {
            if(params.row.totalAttemptsCount !== 0){
              navigateWithClient(`/performance/assessment/${params.id}`)
            }}}
        />
      ) : (
        <NoAssessmentsFound
          icon={'https://assets.languify.in/images/NoPersonalizedAssessment.svg'}
          label={
                  filteredTemplates.length === 0 && searchQuery 
                    ? <>No matches found.<br/> Did you spell everything correctly?</> 
                    : <>You have not created <br />any personalized interview so far!</>
                }
        />
      )}
      {!filteredTemplates?.length ? null :
        <Box p={2} display="flex" justifyContent="center" mt={2}>
          <Pagination
            count={Math.ceil(filteredTemplates.length/5)}
            page={page}
            setPage={setPage}
          />
        </Box>
      }

      <StartAssessmentDialog
        onClose={() => setAssessment(undefined)}
        open={!!assessment}
        template={assessment}
        userMock={true}
      />
    </WhiteBox>
  );
}

export default PersonalizedInterviewTable;
