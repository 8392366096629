import React, { useState, useCallback, useEffect } from 'react';
import { WhiteBox, WhiteBoxHeader } from 'components/AppShell';
import { Box, Divider, Tooltip, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import makeStyles from "@mui/styles/makeStyles";
import dayjs from 'dayjs';
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";

import { SearchTextField } from 'components/CustomFields';
import { round, Session } from 'utils';
import { InterviewRounds, PersonalizedTemplateType } from 'components/CreateInterview/constants';
import CustomButton from 'components/CustomButton';
import HorizontalScrollNavigator from 'components/HorizontalScrollNavigator';
import StartAssessmentDialog from 'dialogs/StartAssessmentDialog';
import TitleTooltip from 'components/TitleTooltip';
import Chip from 'components/Chip';

const useStyles = makeStyles(theme => ({
  headerChildern: {
    display: 'flex',
    gap: theme.spacing(3),
  },
  footerBox: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
  footer: {
    display: 'flex',
    gap: theme.spacing(1),
    marginTop: theme.spacing(4)
  },
  credentialsLeft: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: theme.spacing(3),
    height: '32px',
    width: '183px',
    borderRadius: theme.spacing(1),
  },
  cardContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
    padding: theme.spacing(5),
    borderRadius: theme.spacing(2),
    border: `2px solid ${theme.palette.neutral['clr-200']}`,
    minWidth: '393px',
    cursor: 'pointer',
    "&:hover": {
      border: `2px solid ${theme.palette.primary['clr-100']}`,
    },
    position: 'relative'
  },
  headContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    height: '49px'
  },
  headContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
    justifyContent: "flex-end"
  },
  footContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
    minWidth: 0,
  },
  specificTagBox: {
    padding: theme.spacing(0.5, 2),
    backgroundColor: theme.palette.tertiary['clr-50'],
    borderRadius: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    width: 'fit-content'
  },
  documentTagBox: {
    padding: theme.spacing(0.5, 2),
    backgroundColor: theme.palette.secondary['clr-50'],
    borderRadius: theme.spacing(1),
    width: 'fit-content'
  },
  footHead: {
    width: '310px',
    height: '28px',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 1,
    overflow: 'hidden',
  },
  nameTag: {
    display: 'block',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    minWidth: 0,
    maxWidth: '280px',
  },
  interviewDetails: {
    display: 'flex',
    gap: theme.spacing(4),
  },
  interviewContent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: theme.spacing(1)
  },
  noComponentRoot: {
    height: '100%', width: '100%',
    display: 'flex', justifyContent: 'center', alignItems: 'center',
    flexDirection: 'column', textAlign: 'center'
  },
  noAssessmentDesc: {
    margin: theme.spacing(1, 0),
  },
  imgPosition: {
    position: 'absolute',
    bottom: '0px',
    right: '0px'
  },
  infinityStone: {
    width: '25px',
    height: '25px',
    borderRadius: theme.spacing(5),
    border: `1px solid ${theme.palette.primary['clr-100']}`,
    backgroundColor: theme.palette.neutral['clr-50'],
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: '0px 2px 8px 0px #00000029',
    paddingRight: '1px'
  },
  tooltip: {
    backgroundColor: theme.palette.shades['clr-white-900'],
    color: theme.palette.neutral['clr-700'],
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
    borderRadius: '4px',
    padding: theme.spacing(1, 2),
    ...theme.typography['body02-semiBold'],
    width: '152px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    border: `1px solid ${theme.palette.neutral['clr-500']}`
  },
  arrow: {
    color: theme.palette.shades['clr-white-900'],
    '&::before': {
      border: `1px solid ${theme.palette.neutral['clr-500']}`,
      backgroundColor: theme.palette.shades['clr-white-900'],
      boxSizing: 'border-box',
    },
  },
  topicCard: {
    display: 'flex',
    padding: theme.spacing(0.5, 1),
    border: `1px solid ${theme.palette.primary['clr-100']}`,
    gap: theme.spacing(1),
    borderRadius: theme.spacing(1),
    width: 'fit-content',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: theme.palette.neutral['clr-50']
    }
  },
  scrollableText: {
    display: 'inline-block',
    maxHeight: '3.6em',
    lineHeight: '1.8em',
    whiteSpace: 'nowrap',
    overflowX: 'auto',
    overflowY: 'hidden',
    maxWidth: '361px',
  },
  dropdownBox: {
    width: 'auto',
    maxWidth: '361px',
    display: 'flex',
    alignItems: 'center',
    borderRadius: theme.spacing(1),
    backgroundColor: theme.palette.warning['clr-100'],
    border: `1px solid ${theme.palette.neutral['clr-200']}`,
    position: 'absolute',
    left: '20px',
    marginTop: theme.spacing(1),
    overflowX: 'auto',
    overflowY: 'hidden',
    padding: theme.spacing(1, 1),
    boxShadow: `0px 2px 13px 0px #1961BF52`,
    zIndex: 5
  },
  arrowIcon: {
    transition: 'transform 0.3s ease',
  },
  arrowIconOpen: {
    transform: 'rotate(90deg)',
  },
  "@keyframes moveUp": {
    "0%": { transform: "translateY(20px)" },
    "100%": { transform: "translateY(-20px)" },
  },
  nudgeIcon: {
    fontSize: 16,
    marginRight: theme.spacing(1),
    animation: "$moveUp 1.5s infinite  ease-in-out",
  }
}));

export function NoResultComponent({ clearFilters, description = 'No assessments found' }) {
  const classes = useStyles();

  return (
    <Box className={classes.noComponentRoot} >
      <img
        height="120"
        src="https://assets.languify.in/images/assessment_illustration.svg"
        alt="Not-found"
      />
      <Typography className={classes.noAssessmentDesc} variant='h5-medium' color='neutral.clr-500'>
        {description}
      </Typography>
      {clearFilters && (
        <Box className={classes.clearFilters}>
          <CustomButton onClick={clearFilters}>
            Clear filters
          </CustomButton>
        </Box>
      )}
    </Box>
  );
}

const InterviewTemplateCard = ({ template, onClick }) => {
  const classes = useStyles();
  const theme = useTheme();

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [topics, setTopics] = useState([]);
  const [interviewRound, setInterviewRound] = useState('')
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });
  const [showTooltip, setShowTooltip] = useState(false);

  useEffect(() => {
    if (template?.metadata?.personalizedTemplateType === PersonalizedTemplateType.exam) {
      setTopics(template?.metadata?.topics)
    } else {
      const matchedRound = InterviewRounds.find(
        (round) => round._id === template?.metadata?.interviewRound
      );

      if (matchedRound) {
        setInterviewRound(matchedRound.round);
      } else {
        setInterviewRound('')
      }
    }
  }, [template]);

  const handleDropdownToggle = (e) => {
    e.stopPropagation();
    setIsDropdownOpen((prev) => !prev);
  };

  const handleCardClick = useCallback((e) => {
    if (e.target.closest('.topicCard')) return;
    onClick(template);
  }, [template, onClick]);

  const handleMouseMove = (e) => {
    setTooltipPosition({ x: e.clientX + 10, y: e.clientY + 10 });
  };

  return (
    <Box
      className={classes.cardContainer}
      onClick={handleCardClick}
      onMouseMove={handleMouseMove}
      onMouseEnter={() => setShowTooltip(true)}
      onMouseLeave={() => setShowTooltip(false)}
    >
      {showTooltip && (
        <Box
          sx={{
            position: 'fixed',
            top: `${tooltipPosition.y}px`,
            left: `${tooltipPosition.x}px`,
            background: 'rgba(0, 0, 0, 0.7)',
            color: '#fff',
            padding: '4px 8px',
            borderRadius: '4px',
            pointerEvents: 'none',
            zIndex: 1000,
          }}
        >
          <Typography variant='body02-bold'>
            {template?.totalAttemptsCount === 0 ? 'Click to attempt' : 'Click to reattempt  '}
          </Typography>
        </Box>
      )}
      <Box display='flex' flexDirection='column' gap='8px'>
        <Box className={classes.headContainer}>
          <Box className={classes.headContent}>
            <Box className={classes.specificTagBox}>
              <Typography variant='body02-bold' >
                {template?.metadata?.personalizedTemplateType === PersonalizedTemplateType.exam
                  ? 'UPSC Prep' : template?.metadata?.personalizedTemplateType === PersonalizedTemplateType.report
                    ? 'Report' : template?.metadata?.personalizedTemplateType === PersonalizedTemplateType.resume
                      ? 'Resume only' : template?.metadata?.personalizedTemplateType === PersonalizedTemplateType.jobDescription
                        ? 'Job description only' : 'Job description + Resume'}
              </Typography>
            </Box>
            {topics?.length === 0 ? (
              <Box className={classes.documentTagBox}>
                <Typography variant='body02-bold'>
                  {template?.metadata?.personalizedTemplateType === PersonalizedTemplateType.report ? '' : interviewRound}
                </Typography>
              </Box>
            ) : (
              <Box>
                <Box onClick={handleDropdownToggle} className={classes.topicCard}>
                  <Typography variant="body02-bold" color="primary.clr-300" sx={{ textDecoration: 'underline' }}>
                    Topics {topics?.length}
                  </Typography>
                  <img
                    src="https://assets.languify.in/images/chevron-right-icon.svg"
                    alt="arrow"
                    className={`${classes.arrowIcon} ${isDropdownOpen ? classes.arrowIconOpen : ''}`}
                  />
                </Box>
                {isDropdownOpen && (
                  <Box className={classes.dropdownBox} onClick={(e) => e.stopPropagation()}>
                    <Typography
                      variant="body02-bold"
                      color="neutral.clr-900"
                      className={`${classes.scrollableText}`}
                    >
                      {topics
                        .map((item) => (item._id === 'optional_subject' ? 'Optional Subject' : item.topic))
                        .join(' · ')}
                    </Typography>
                  </Box>
                )}
              </Box>
            )}
          </Box>
          <Box className={classes.infinityStone}>
            <Tooltip
              title={<Box>
                <> You have unlimited reattempts</>
                <img
                  src='https://assets.languify.in/images/sparkles.gif'
                  alt='sparkels'
                  width={12}
                  height={12}
                />
              </Box>}
              arrow
              placement="top"
              PopperProps={{
                modifiers: [
                  {
                    name: 'flip',
                    enabled: false,
                  },
                ],
              }}
              classes={{ tooltip: classes.tooltip, arrow: classes.arrow }}
            >
              <img
                src='https://assets.languify.in/images/todo_infinity_icon.svg'
                alt='infinity'
              />
            </Tooltip>
          </Box>
        </Box>
        <Divider sx={{ border: `0.5px solid ${theme.palette.neutral['clr-200']}` }} />
        <Box className={classes.footContent}>
          <TitleTooltip
            placement='bottom'
            component={template?.name}
            disableHoverListener={template?.name.length < 23}
            positionStyle={{ top: '-95px !important', left: '-10px !important', zIndex: 2 }}
            onClick={handleCardClick}

          >
            <Box className={classes.footHead}>
              <Typography variant='h5-semiBold'>{template?.name}</Typography>
            </Box>
          </TitleTooltip>
          <Box display='flex' flexDirection='column' gap='10px'>
            {template.totalAttemptsCount === 0 ?
              (
                <Chip
                  content='Attempt now & unlock progress'
                  bgColor={theme.palette.warning['clr-100']}
                  color={theme.palette.warning['clr-700']}
                  typographyVariant='body02-bold'
                  sx={{
                    borderRadius: '4px',
                    padding: '4px'
                  }}
                />
              )
              :
              template?.highestScore >= 90 ?
                (
                  <Chip
                    content='Keep practicing it for interview preparation'
                    bgColor={theme.palette.neutral['clr-50']}
                    typographyVariant='body02-bold'
                    sx={{
                      borderRadius: '4px',
                      padding: '4px'
                    }}
                    startIcon={
                      <Box>
                        <img src='https://assets.languify.in/images/Fire.svg' alt='fire' />
                      </Box>
                    }
                  />
                )
                : (
                  <Chip
                    content='Reattempt & improve score'
                    bgColor={theme.palette.success['clr-100']}
                    typographyVariant='body02-bold'
                    color={theme.palette.success['clr-700']}
                    sx={{
                      borderRadius: '4px',
                      padding: '4px'
                    }}
                    endIcon={
                      <Box sx={{ overflow: 'hidden' }}>
                        <ArrowUpwardIcon className={classes.nudgeIcon} />
                      </Box>
                    }
                  />
                )}
            <Box className={classes.interviewDetails}>
              <Box className={classes.interviewContent}>
                <img
                  src='https://assets.languify.in/images/refreshIcon.svg'
                  alt='practice'
                />
                <Typography variant='body01-semiBold' color='neutral.clr-700'>
                  {template?.totalAttemptsCount}x Attempted
                </Typography>
              </Box>
              <Box className={classes.interviewContent}>
                <img src='https://assets.languify.in/images/barGraph.svg' alt='bar' />
                <Typography variant='body01-semiBold' color='neutral.clr-700'>
                  Highest scored:
                </Typography>
                <Typography
                  variant='body01-semiBold'
                  color={template?.highestScore > 89 ? 'success.clr-700' : 'danger.clr-700'}
                >
                  {round(template?.highestScore)}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
        <img
          className={classes.imgPosition}
          src={
            template?.metadata?.personalizedTemplateType === PersonalizedTemplateType.exam
              ? 'https://assets.languify.in/images/personalizedinterview_dafbgImg.png'
              : template?.metadata?.personalizedTemplateType === PersonalizedTemplateType.report
                ? 'https://assets.languify.in/images/ReportBased.png'
                : template?.metadata?.personalizedTemplateType === PersonalizedTemplateType.resume
                  ? 'https://assets.languify.in/images/jobRoleSpecific.png'
                  : 'https://assets.languify.in/images/companySpecific.png'}
          alt='specific'
          width={60}
          height={60}
        />
      </Box>
    </Box>
  );
};


const TodoList = ({ interviewTemplate, allowTemplateCreation }) => {
  const classes = useStyles();
  const theme = useTheme();
  const navigate = useNavigate();

  const [template, setTemplate] = useState();
  const [filteredTemplates, setFilteredTemplates] = useState(interviewTemplate);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    const filtered = interviewTemplate?.filter((t) =>
      searchQuery === '' || t.name.toLowerCase().includes(searchQuery.toLowerCase())
    )
      .sort((a, b) => {
        if (a.totalAttemptsCount !== b.totalAttemptsCount) {
          return (a.totalAttemptsCount || 0) - (b.totalAttemptsCount || 0);
        }

        return dayjs(b.createdAt).isAfter(dayjs(a.createdAt)) ? 1 : -1;
      });

    setFilteredTemplates(filtered);
  }, [searchQuery, interviewTemplate]);

  return (
    <WhiteBox sx={{ position: 'relative', marginTop: theme.spacing(4) }}>
      <WhiteBoxHeader
        id='to-do-list'
        heading="My personalized interviews"
        sx={{ marginBottom: theme.spacing(4) }}
        subheading='Reattempt your self-created interviews anytime'
      >
        <Box className={classes.headerChildern}>
          <SearchTextField
            placeholder="Find your interview"
            onChange={(e) => setSearchQuery(e.target.value)}
            disabled={!interviewTemplate?.length}
          />
          <CustomButton
            variant='contained'
            size='medium'
            onClick={() => navigate('create')}
            disabled={!allowTemplateCreation}
          >
            Create new interview
          </CustomButton>
        </Box>
      </WhiteBoxHeader>
      <Divider />
      <Box height={280} mt={5}>
        {filteredTemplates?.length ?
          (
            <HorizontalScrollNavigator
              childrensLength={filteredTemplates?.length}
              scrollWidth={800}
            >
              {filteredTemplates.map((template, index) => (
                <InterviewTemplateCard
                  key={index}
                  template={template}
                  onClick={(t) => setTemplate(t)}
                />
              ))}

              <StartAssessmentDialog
                onClose={() => setTemplate(undefined)}
                open={!!template}
                template={template}
                userMock={true}
              />
            </HorizontalScrollNavigator>
          )
          :
          <NoResultComponent
            description={<>No matches found.<br /> Did you spell everything correctly?</>}
          />
        }
      </Box>
    </WhiteBox>
  );
}

export default TodoList;
