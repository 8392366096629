import React from 'react';
import Box from '@mui/material/Box';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';

import { round } from 'utils';
import { SubCategoryMap } from './constants';
import Chip from 'components/Chip';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    gap: 2,
    overflowX: 'auto',
    '&::-webkit-scrollbar' : {
      width: '8px',
      height: '6px'
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: '#F0F5FA',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#BFCAD9',
      borderRadius: '4px',
    },
  },
  multiTag: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(1, 2),
    borderRadius: '4px',
    width: 'fit-content',
    minWidth: 'fit-content',
    margin: theme.spacing(2, 0),
    ...theme.typography['body01-semiBold'],
    overflow: 'auto'
  },
}));

const ColorCodes = {
    'good': { color: '#00664A', bgColor: '#DCFCE7' },
    'avg': { color: '#BF7900', bgColor: '#FFF6E5' },
    'low': { color: '#C51407', bgColor: '#FFE6E3' },
};

function ArrayTag({ value, subCategory }) {
    const classes = useStyles();

    const { color, bgColor, } = React.useMemo(() => {

        let colorCodes = ColorCodes.low;

        if (['RARE_USED_WORDS','JARGON_WORDS'].includes(subCategory)) {
            colorCodes = ColorCodes.good;
        }

        return colorCodes;
    }, [value, subCategory]);

    return <Box className={classes.root}>
      {
        value.length ? value.map((v) => (
            <Box
                className={classes.multiTag}
                style={{ backgroundColor: bgColor, color: color }}
            >
                {v.word} x {v.count}
            </Box>
        ))
        :
        <Box
            className={classes.multiTag}
            style={
              subCategory === SubCategoryMap.INFORMAL_WORDS 
                ? { backgroundColor:   'rgb(220, 252, 231)', color: 'rgb(0, 102, 74)' } 
                : { backgroundColor:  '#F0F5FA', color: '#111519' }
            }
        >
          No word present!
        </Box>
      }
    </Box>;
}

const VOCAL_DELIVERY = {
  PACE: {
    valueParser: (value) => {
      return `${value} wpm`
    }
  },
  ENERGY: {
    valueParser: (value) => {
      return `${(value < 1 ? 'Dull' : (value <= 2 ? 'Ideal' : 'High'))}`
    }
  }
}

function NumberTag({ value, subCategory }) {
    const classes = useStyles();
    const filterValue = round(value);

    const { color, label, hasChip, bgColor, showTag, valueChip } = React.useMemo(() => {
        let data = {
            color: ColorCodes.low.color,
            bgColor: ColorCodes.low.bgColor,
            label: 'NA',
            hasChip: false,
            showTag: true
        };

        switch (subCategory) {
            case 'PAUSES':

                if (filterValue < 70) {
                    data.hasChip = true;
                    data.label = `Pauses encountered : ${filterValue}`;
                } else {
                    data.hasChip = true;
                    data.label = `Pauses encountered : ${filterValue}`;
                }
                break;
            case 'DISFLUENCY':

                data.hasChip = true;
                data.label = `Breakdowns encountered : ${filterValue}`;
                if (filterValue === 0) {
                    data.color = ColorCodes.good.color;
                    data.bgColor = ColorCodes.good.bgColor;
                }
                break;
            case 'PACE':
                data.valueChip = VOCAL_DELIVERY.PACE.valueParser(filterValue)
                if (filterValue > 150) {
                    data.label = 'Your Speaking Pace was High';
                } else {
                    if (filterValue <= 150 && filterValue >= 100) {
                        data.label = 'Your Speaking Pace was Ideal';
                        data.color = ColorCodes.good.color;
                        data.bgColor = ColorCodes.good.bgColor;
                        data.showTag = false;
                    } else {
                        data.label = 'Your Speaking Pace was Low';
                        data.color = ColorCodes.avg.color;
                        data.bgColor = ColorCodes.avg.bgColor;
                    }
                }
                break;
            case 'ENERGY':
                data.valueChip = VOCAL_DELIVERY.ENERGY.valueParser(filterValue)
                if (filterValue < 1) {
                    data.label = 'Your Speaking Energy was Low';
                    data.color = ColorCodes.avg.color;
                    data.bgColor = ColorCodes.avg.bgColor;
                  }
                  if (filterValue >= 1 && filterValue <= 2) {
                    data.label = 'Your Speaking Energy was Ideal';
                    data.color = ColorCodes.good.color;
                    data.bgColor = ColorCodes.good.bgColor;
                    data.showTag = false
                }
                break;

            default:
                break;
        }

        return data;
    }, [value, subCategory]);

    if (!showTag) return null;

    return ( hasChip ?
        <>
          <Box
            className={classes.multiTag}
            style={{ backgroundColor: bgColor, color: color }}
          >
            {label}
          </Box>
        </>
        :
        <>
          <Typography variant='body01-bold' color={color} my={2}>
            {label}
            {
              valueChip &&
                <Chip
                  sx={{padding: '2px 10px', display: 'inline', marginLeft: '8px'}}
                  content={valueChip}
                  bgColor={bgColor}
                  color={color} 
                />
            }
          </Typography>
        </>
    )
}

export function ParameterTag({ tag }) {
    const { value, subCategory } = tag || {};

    return (
      <>
        {
          Array.isArray(value) ? <ArrayTag value={value} subCategory={subCategory} /> : null
        }
        {
          Number.isInteger(value)
            ? <NumberTag value={subCategory === 'PAUSES' ? tag.count : value} subCategory={subCategory} /> 
            : null
        }
      </>
    );
}

export default ParameterTag;