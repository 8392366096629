import React from "react";
import Box from "@mui/material/Box";
import makeStyles from "@mui/styles/makeStyles";
import { shallow } from "zustand/shallow";
import Tutorial from "components/Tutorial";
import PostAssessment from "./PostAssessment";
import RateExperience from "./RateExperience";
import WavRecorder from "utils/WavRecorder";
import EndInterview from './EndInterview';
import MiddleSection from './MiddleSection';
import FooterSection from './FooterSection';
import TotalTimer from "./TotalTimer";
import FullscreenPermissionDialog from "dialogs/FullscreenPermissionDialog";
import useAssessmentStore from "./assessmentStore";

import { useSearchParams } from "react-router-dom";
import { SingleSessionProviderContext, useServices, useSnackbar } from "contexts";
import { useNavigateWithClient } from "hooks";
import {
    analyseAnswer,
    finishInterviewAttempt,
    getTemplateById,
    saveInterviewAttempt,
    skipQuestion,
} from 'services';
import { AssessmentStates, QuestionStates, TutorialTips } from "./constants";
import { ProctoringContext } from "contexts/ProctoringProvider";
import { useContext } from "react";
import { Session, round } from "utils";
import { useStackSnackbar } from "contexts/StackSnackbarProvider";
import { templateTypes } from "components/PerformanceReview/constants";

const useStyles = makeStyles(theme => ({
    root: {
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: 'space-between',
        height: "100vh",
        width: "100vw",
        backgroundColor: theme.palette.neutral['clr-50'],
        zIndex: 0,
    },
    retake: {
        color: 'white', background: 'transparent', textTransform: 'capitalize',
        border: 'none', cursor: 'pointer', borderBottom: '1px solid white', padding: 0,
    },
    header: {
        backgroundColor: '#fff', width: '100%', display: 'flex', height: '90px',
        justifyContent: 'space-between', alignItems: 'center', padding: '0px 40px'
    },
    postAssesment: {
        height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center'
    }
}));

function Assessment() {
    const classes = useStyles();
    const [searchParams] = useSearchParams();
    const snackbar = useSnackbar();
    const service = useServices();
    const navigate = useNavigateWithClient();
    const { showStackSnackbar, closeStackSnackbar } = useStackSnackbar();

    const hasIframe = React.useMemo(() => window.top !== window.self, []);

    const wavRecorder = React.useRef(null);
    const questionTimeTakenRef = React.useRef(0);

    const template = useAssessmentStore(state => state.template);
    const attempt = useAssessmentStore(state => state.attempt);
    const setAttempt = useAssessmentStore(state => state.setAttempt);
    const reset = useAssessmentStore(state => state.reset);
    const totalTime = useAssessmentStore(state => state.totalTime);
    const remainingInterviewTime = useAssessmentStore(state => state.remainingInterviewTime);
    const setError = useAssessmentStore(state => state.setQuestionError);
    const setUploadState = useAssessmentStore(state => state.setUploadState);
    const setUploadProgress = useAssessmentStore(state => state.setUploadProgress);
    const start = useAssessmentStore(state => state.start, shallow);
    const setTemplate = useAssessmentStore(state => state.setTemplate);
    const setIsPersonalizedInterview = useAssessmentStore(state => state.setIsPersonalizedInterview);
    const fetchQuestion = useAssessmentStore(state => state.fetchQuestion);
    const { _showDuplicateSessionDialog } = React.useContext(SingleSessionProviderContext);

    const setCurrentQuestion = useAssessmentStore(state => state.setCurrentQuestion, shallow);
    const {
        endProctoring,
        getProctoringData,
        activateFullScreenProctoring,
        activateGeneralProctoring,
        activateImageProctoring,
        enableFullscreenMode,
        fullScreenActive
    } = useContext(ProctoringContext);

    const [questionState, setQuestionState] = useAssessmentStore(
        state => [state.questionState, state.setQuestionState],
        shallow
    );
    const [assessmentState, setAssessmentState] = useAssessmentStore(
        state => [state.assessmentState, state.setAssessmentState],
        shallow
    );
    const [openFullscreenDialog, setOpenFullscreenDialog] = React.useState(false);

    const [
        startTutorial, endTutorial, currentQuestion, questions, next,
        startAssessment, isTimeout,
    ] = useAssessmentStore(state => [
        state.startTutorial, state.endTutorial, state.currentQuestion,
        state.questions, state.next, state.startAssessment, state.isTimeout,
    ], shallow);

    const question = React.useMemo(
        () => questions[currentQuestion],
        [questions, currentQuestion]
    );

    const resultCheckerIntervalRef = React.useRef();

    React.useEffect(() => {
        reset();
    }, [])

    React.useEffect(() => {
        (async () => {
            try {
                const tid = searchParams.get("tid");
                const template = await getTemplateById(tid, { 'module': true });
                activateFullScreenProctoring();
                if (template?.proctorSettings?.generalProctoring) {
                    activateGeneralProctoring();
                }

                if (template.type === templateTypes.PERSONALIZED)
                    setIsPersonalizedInterview(true);

                setTemplate(template);
            } catch (error) {
                console.error(error);
                snackbar.error("Uh Oh! Unable to start the mock. Please try again!");
            }
        })();
    }, []);

    React.useEffect(() => {
        if (assessmentState === AssessmentStates.FULLSCREEN_PERMISSION) {
            setOpenFullscreenDialog(true);
        }
    }, [assessmentState]);

    React.useEffect(() => {
        const listener = () => window.history.go(1);

        window.addEventListener('popstate', listener);
        console.log("Added popstate event");

        return () => {
            console.log("Removed popstate event");
            window.removeEventListener('popstate', listener);
            endProctoring();
            reset();
        };
    }, []);

    React.useEffect(() => {
        if (template && assessmentState === AssessmentStates.RUNNING) {
            const {
                proctorSettings = {}, metadata: { testTime } = {}
            } = template;

            if (proctorSettings?.imageCaptureProctoring) {
                const captureRate = round((testTime * 1000) / 10);
                activateImageProctoring(20000).catch(console.error);
            }

            if (proctorSettings?.generalProctoring) {
                activateGeneralProctoring();
            }
        }

        return () => endProctoring();
    }, []);

    const handleSuccessFullscreenMode = () => {
        enableFullscreenMode();
        setOpenFullscreenDialog(false);
        if (assessmentState === AssessmentStates.FULLSCREEN_PERMISSION) {
            fetchQuestion();
        }
    };

    const handleDialogClosed = () => {
        Session.clearSession();
        navigate("/login");
    };

    const startInterview = React.useCallback(async () => {
        try {
            const templateId = searchParams.get("tid");

            if (attempt._id) return;

            const attemptResult = await service.callService(
                saveInterviewAttempt,
                null,
                {
                    interviewTemplate: templateId,
                    startedAt: new Date().toISOString(),
                    totalQuestions: questions.length,
                    pathway: template.pathway,
                    batch: sessionStorage.getItem("selectedBatch")
                }
            );
            setAttempt(attemptResult);
            startAssessment();

            window?.parent?.postMessage(
                { event: "ASSESSMENT_STARTED", attemptId: attemptResult._id },
                "*"
            );
        } catch (error) {
            console.log(error);
            endProctoring();
            snackbar.error(error.message ||
                "Uh Oh! Unable to start the mock. Please try again!");
            navigate("/home");
        }
    }, [attempt, questions, searchParams]);

    const finishInterview = React.useCallback(async () => {
        if (!attempt?.finishedAt) {
            try {
                const proctoringRecords = getProctoringData();

                const { isActive } = await service.callService(
                    finishInterviewAttempt, attempt._id, isTimeout, proctoringRecords
                );

                if (isActive) {
                    setAttempt({ ...attempt, finishedAt: new Date() });
                    setAssessmentState(AssessmentStates.COMPLETED);
                    window?.parent?.postMessage(
                        { event: "ASSESSMENT_FINISHED", attemptId: attempt._id },
                        "*"
                    );
                } else {
                    reset();
                    snackbar.info("Attempt not considered, none of the answers recorded!");
                    wavRecorder.current?.stop();

                    (Session.magicLinkRedirectUrl) ?
                        window.open(Session.magicLinkRedirectUrl, "_top") :
                        navigate("/home");
                }
            } catch (error) {
                console.error(error);
                setTimeout(finishInterview, 3000);
            }
        } else {
            navigate("/home");
        }
    }, [service, attempt, isTimeout]);

    const checkResults = React.useCallback(() => {
        if ("RECORDING" === questionState) {
            setQuestionState(QuestionStates.SUBMITTED);
            return false;
        }

        for (let i in questions) {
            const q = questions[i] || {};
            const { submitted, recorded, visited } = q;
            if (recorded && !submitted) return false;

            if (!visited || (visited && !submitted)) {
                q.visited = true;
                q.submitted = true;
                service.callService(
                    skipQuestion,
                    {
                        questionId: q._id,
                        attemptId: attempt._id,
                        timeTaken: 0,
                        transcript: '',
                        serialNumber: i,
                    })
                    .then(console.log)
                    .catch(console.error)
                    .finally(() => {
                        q.analysisRecieved = true;
                    });
            }
        }

        for (const q of questions) {
            const { analysisRecieved, submitted } = q;
            if (submitted && !analysisRecieved) return false;
        }

        return true;
    }, [question, questionState, attempt, questions]);

    const handleAudioSuccess = React.useCallback(async (audioBlob) => {
        setQuestionState(QuestionStates.WAITING);

        const questionId = question._id;

        question.analysisData = audioBlob;
        question.recorded = true;

        const cQ = currentQuestion;

        let analysisData = {
            attemptId: attempt._id,
            questionId,
            timeTaken: question.timeTaken || 0,
            serialNumber: cQ,
            audioBlob,
            template: template._id,
            domain: template.domain?._id ? template.domain?._id : template.domain,
        };

        if (template.metadata?.personalizedTemplateType === 'exam') {
            analysisData.isDaf = true;
            analysisData.domain = question?.metadata?.domainName;
        }

        try {
            const handleEvent = (event, data) => {
                setUploadState(cQ, event === 'progress');

                if (data?.name === "DuplicateSession") {
                    _showDuplicateSessionDialog(
                        `Already logged in on ${data.browser}, ${data.os}.
                    Do you wish to login here?`,
                        async () => {
                            handleDialogClosed();
                        },
                    );
                }

                if (event === 'progress') {
                    setUploadProgress(cQ, data);
                } else {
                    setError(
                        cQ,
                        event === 'error' || data?.analysis?.isError
                    );

                    if (event === 'error') {
                        showStackSnackbar(
                            `Q${cQ + 1} Not Recorded Properly:`,
                            {
                                variant: 'error', persist: true, action: (id) => (
                                    <button className={classes.retake}
                                        onClick={() => {
                                            closeStackSnackbar(id);
                                            setCurrentQuestion(cQ);
                                            start();
                                        }}
                                    >
                                        Retake now
                                    </button>
                                )
                            }
                        );
                    }
                }

                question.analysisRecieved = event !== 'progress';
            };

            analyseAnswer(analysisData, handleEvent)
        } catch (error) {
            setError(cQ, true);
            console.error(error);
        }
    }, [
        question, attempt, currentQuestion, setQuestionState, setError,
        setUploadProgress, setUploadState, showStackSnackbar
    ]);

    React.useEffect(() => {
        switch (assessmentState) {
            case AssessmentStates.LOADED:
            case AssessmentStates.SHOWING_TUTORIAL: {
                const hasShowed = JSON.parse(localStorage?.getItem("1.4-tutorial-showed"));
                if (hasShowed) {
                    endTutorial();
                } else {
                    localStorage?.setItem("1.4-tutorial-showed", "true");
                    startTutorial();
                }
                break;
            }

            case AssessmentStates.STARTING: {
                startInterview();
                break;
            }

            case AssessmentStates.FINISHING: {
                endProctoring();
                finishInterview();
                break;
            }
            case AssessmentStates.COMPLETED: {
                wavRecorder.current?.stop();
                wavRecorder.current = null;
                break;
            }
            default:
        }
    }, [assessmentState]);

    React.useEffect(() => {
        if (assessmentState === AssessmentStates.WAITING_FOR_RESULT) {
            resultCheckerIntervalRef.current = setInterval(() => {
                if (checkResults()) {
                    clearInterval(resultCheckerIntervalRef.current);
                    setAssessmentState(AssessmentStates.FINISHING);
                }
            }, 1000);
        }

        return () => {
            if (resultCheckerIntervalRef.current)
                clearInterval(resultCheckerIntervalRef.current)
        }
    }, [assessmentState, checkResults]);

    React.useEffect(() => {
        if (questionState === QuestionStates.SUBMITTED) {
            setError(currentQuestion, undefined);
            wavRecorder?.current?.stop(handleAudioSuccess);

            const timeTaken = round(
                (Date.now() - questionTimeTakenRef.current) / 1000
            );

            if (assessmentState === AssessmentStates.RUNNING) {
                next();
            }

            question.timeTaken = timeTaken;
            question.submitted = true;
            question.recorded = false;
            question.skipped = false;
            question.visited = true;
            question.analysisRecieved = false;
        }

        if (questionState === QuestionStates.RECORDING) {
            wavRecorder.current = new WavRecorder();
            wavRecorder.current.start();
            questionTimeTakenRef.current = Date.now();

            question.submitted = false;
            question.recorded = true;
            question.skipped = false;
            question.visited = true;
            question.analysisRecieved = false;
        }
    }, [questionState, assessmentState]);

    return (
        <Box className={classes.root}>
            {[
                AssessmentStates.WAITING_FOR_RESULT,
                AssessmentStates.FINISHING,
                AssessmentStates.COMPLETED,
            ].includes(assessmentState) ? (
                <Box className={classes.postAssesment}>
                    <PostAssessment assessmentState={assessmentState} />
                </Box>
            ) : (
                <>
                    <Box className={classes.header}>
                        <TotalTimer time={totalTime} template={template} />
                        <EndInterview assessmentState={assessmentState} />
                    </Box>
                    <Box>
                        <MiddleSection />
                    </Box>
                    <Box style={{ width: '100%' }}>
                        <FooterSection />
                    </Box>
                </>
            )
            }
            <RateExperience assessmentState={assessmentState} />
            <Tutorial
                open={assessmentState === AssessmentStates.SHOWING_TUTORIAL}
                tips={TutorialTips}
                onSkip={endTutorial}
            />
            <FullscreenPermissionDialog
                open={
                    openFullscreenDialog ||
                    (!hasIframe && !fullScreenActive && assessmentState !== AssessmentStates.SHOWING_TUTORIAL)
                }
                onSuccess={handleSuccessFullscreenMode}
                remainingTime={
                    (
                        assessmentState !== AssessmentStates.FULLSCREEN_PERMISSION &&
                        assessmentState !== AssessmentStates.SHOWING_TUTORIAL &&
                        !fullScreenActive
                    )
                        ? remainingInterviewTime : false
                }
            />
        </Box>
    );
};

export default Assessment;