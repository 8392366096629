import create from "zustand";
import { DocumentOptions, InterviewSteps } from "./constants";

export const defaults = {
  selectedDocument: DocumentOptions.JD_RESUME._id,
  interviewName : '',
  activeStep : '',
  stepNumber : 1,
  companyName : '',
  jobRole : '',
  jobDescription:'',
  domain:'',
  selectedResumeDetails : {
    name:'',
    createdOn:'',
    lastUsedFor:''
  },
  parsedData:'',
  templateDetails : {
    batch :'',
    publishOn :'',
    expiresOn:'',
    proctorSettings:{
      copyPasteProctoring:false,
      generalProctoring:true,
      imageCaptureProctoring:false
    }
  },
  draft : '',
  selectedResumeId: '',
  dafExtractedData : {
    name : '',
    user : '',
    uploaded : [false , false],
    state : '',
    district : '',
    stream : '',
    attempts : null,
    achievements : '',
    interests : '',
    sports : '',
    leadership: '',
    optionalSubjects: '',
    education : {
      summary : ''
    },
    experience: {
      summary : ''
    },
    preferences : []
  },
  dafId : '',
  isNextStep : true,
}

const useStore = create ((set,get)=>({
  ...defaults,
  setSelectedDocument : (selectedDocument) => set({selectedDocument}),
  setInterviewName : (interviewName) => set({interviewName}),
  setActiveStep : (activeStep) => set({activeStep}),
  setStepNumber : (stepNumber) => set({stepNumber}),
  setCompanyName : (companyName) => set({companyName}),
  setJobRole : (jobRole) => set({jobRole}),
  setJobDescription : (jobDescription) => set({jobDescription}),
  setDomain : (domain) => set({domain}),
  setSelectedResumeDetails : (selectedResumeDetails) => set({selectedResumeDetails}),
  setParsedData : (parsedData) => set({parsedData}),
  setSelectedResumeId : (selectedResumeId) => set({selectedResumeId}),
  setDafExtractedData : (dafExtractedData) => set({dafExtractedData}),
  setDafId : (dafId) => set({dafId}),
  setIsNextStep : (isNextStep) => set({isNextStep}),
  
  init : ({
    activeStep = InterviewSteps.JOB_DETAILS._id,
    stepNumber = 1,
    companyName = '',
    jobRole = '',
    jobDescription = '',
    domain = '',
    selectedResumeDetails,
    parsedData = '',
    templateDetails,
    draft='',
    selectedResumeId = "",
    interviewName,
    })=>{
      set({
        activeStep,
        stepNumber,
        companyName,
        jobRole,
        jobDescription,
        domain,
        selectedResumeDetails,
        parsedData,
        templateDetails,
        draft,
        interviewName,
        selectedResumeId
    })
  },
  reset : () => set(defaults),
}))

export default useStore;