import LogRocket from 'logrocket';

class SessionSingleton {
  userId = "";
  user = "";
  accessToken = "";
  accessTokenExpiry = Date.now();
  refreshToken = "";
  refreshTokenExpiry = Date.now();
  roles = {};
  magicLinkRedirectUrl = "";

  _setUser(user) {
    this.userId = user._id;
    this.user = user;
    if (this.user.client) {
      this.client = user.client;
      this.clientId = user.client._id;
    }
    this.redirectUrl = this.user?.metadata?.redirectUrl ||
      this.client?.metadata?.redirectUrl;
    this.role = user?.role;

    if (process.env.NODE_ENV === 'production') {
      LogRocket.identify(
        this.userId, { email: this.user.email, client: this.client.email }
      );
    }
  }

  _unsetUser() {
    this.userId = "";
    this.user = null;
    this.clientId = "";
    this.client = null;
    this.role = {};
  }

  getUser() {
    return this.user || {};
  }

  getUserMetadata() {
    return this.user?.metadata || {};
  }

  getClientMetadata() {
    return this.client?.metadata || this.user?.metadata || {};
  }

  onCreateSession({ user, tokens, sessionId, redirectUrl }) {
    this._setUser(user);
    this.accessToken = tokens.accessToken;
    this.accessTokenExpiry = new Date(tokens.accessExpireAt).getTime();
    this.refreshToken = tokens.refreshToken;
    this.refreshTokenExpiry = new Date(tokens.refreshExpireAt).getTime();
    this.magicLinkRedirectUrl = redirectUrl || "";
    localStorage?.setItem("languifySessionId", sessionId);
    localStorage?.setItem("languifyIsLoggedIn", true.toString());
    localStorage?.setItem("languifyRefreshTokenExpiry", this.refreshTokenExpiry.toString());
    localStorage?.setItem("languifyRefreshToken", this.refreshToken);
  }

  onRestoreSession({ accessToken, accessExpireAt, user, sessionId }) {
    this._setUser(user);
    this.accessToken = accessToken;
    this.accessTokenExpiry = new Date(accessExpireAt).getTime();
    localStorage?.setItem("languifyIsLoggedIn", true.toString());
    localStorage?.setItem("languifySessionId", sessionId);
  }

  clearSession() {
    try {
      this.accessToken = "";
      this.accessTokenExpiry = Date.now();
      this.refreshToken = "";
      this.refreshTokenExpiry = Date.now();
      localStorage?.removeItem("languifySessionId");
      localStorage?.removeItem("languifyIsLoggedIn");
      localStorage?.removeItem("languifyRefreshTokenExpiry");
      localStorage?.removeItem("languifyRefreshToken");
      sessionStorage.clear();
      this._unsetUser();
    } catch (error) {
      console.log(error);
    }
  }

  isLoggedIn() {
    return JSON.parse(localStorage?.getItem("languifyIsLoggedIn"));
  }

  checkDeactivatedStatus() {
    const user = this.getUser();

    if (user && this.isLoggedIn()) {
      if (user.deactivated) {
        console.error("DEBUG::checkDeactivatedStatus: USER DEACTIVATED");
        return true;
      } else {
        if (this?.client?.deactivated) {
          console.error("DEBUG::checkDeactivatedStatus: CLIENT DEACTIVATED");
          return true;
        }
        return false;
      }
    }
  }

}

export const Session = new SessionSingleton();

export default Session;