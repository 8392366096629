import React from 'react'
import { Box, Typography } from '@mui/material';

import makeStyles from "@mui/styles/makeStyles";
import { nextStepAudio } from 'utils';
import useStore from './store';
import { Interviewers } from 'components/Assessment/constants';

const useStyles = makeStyles((theme) => ({
  container : {
    display : 'flex',
    flexDirection : 'column',
    marginTop : theme.spacing(13),
    gap : theme.spacing(5),
    padding : theme.spacing(0,6),
    animation: '$slideInFromRight 0.6s ease-out',
  },
  containerBack : {
    display : 'flex',
    flexDirection : 'column',
    marginTop : theme.spacing(13),
    gap : theme.spacing(5),
    padding : theme.spacing(0,6),
    animation: '$slideInFromLeft 0.6s ease-out',
  },
  '@keyframes slideInFromRight': {
    '0%': {
      transform: 'translateX(15px)',
      opacity: 0
    },
    '100%': {
      transform: 'translateX(0)',
      opacity: 1
    }
  },
  '@keyframes slideInFromLeft': {
    '0%': {
      transform: 'translateX(-5px)',
      opacity: 0
    },
    '100%': {
      transform: 'translateX(0)',
      opacity: 1
    }
  },
  interviewerBox : {
    padding : theme.spacing(2),
    display : 'flex',
    gap : theme.spacing(2.5),
    borderRadius : theme.spacing(1),
    border : `1px solid ${theme.palette.neutral['clr-300']}`,
    position : 'relative',
    cursor : 'pointer',
    '&:hover' : {
      border :  `1px solid ${theme.palette.primary.main}`,
      boxShadow: `0px 2px 8px 0px #D2D2D299`
    }
  },
  background : {
    position : 'absolute',
    width : '60px',
    height : '100%',
    borderTopLeftRadius : theme.spacing(1),
    borderBottomLeftRadius : theme.spacing(1),
    top : 0,
    left : 0
  }
}))

const SelectInterviewer = ({stepNumber , next , setInterviewer}) => {
  const classes = useStyles();

  const isNextStep = useStore(store => store.isNextStep)
  const setIsNextStep = useStore(store => store.setIsNextStep)

  const handleInterviewRound = (id) => { 
    nextStepAudio();
    setIsNextStep(true);
    setInterviewer(id)
    next(stepNumber + 1);
  }

  return (
    <Box className={isNextStep ? classes.container : classes.containerBack}>
       <Typography 
        variant='h5-medium' 
        color='neutral.clr-600' 
      >
        Choose personality of your interviewer...
      </Typography>
      <Box display='flex' flexDirection='column' gap='12px'>
        {Object.values(Interviewers).map((data,index) => (
          <Box 
            key={index} 
            className={classes.interviewerBox} 
            onClick={() => handleInterviewRound(data.voiceId)}
          >
            <Box 
              className={classes.background} 
              sx={{backgroundColor : data.background}}
            >
            </Box>
            <Box display='flex' justifyContent='center' alignItems='center'>
              <img 
                src={data.image}
                alt='interviewer'
                width={68}
                height={68}
                style={{zIndex : 10}}
              />
            </Box>
            <Box display='flex' flexDirection='column' gap='6px'>
              <Typography variant='body01-bold' color='neutral.clr-800'>{data.name}</Typography>
              <Typography variant='body02-semiBold' color='neutral.clr-500'>{data.experience}</Typography>
              <Typography variant='body02-semiBold' color='neutral.clr-600'>{data.difficulty}</Typography>
            </Box>
          </Box>
        ))}
       
      </Box>
    </Box>
  )
}

export default SelectInterviewer